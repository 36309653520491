import React, { useState, useEffect } from 'react';

// MUI components and icons
import { Grid, Paper, Box, Typography, Button, Autocomplete, TextField, LinearProgress } from "@mui/material/";
import { ChevronLeft, AccountBalance } from "@mui/icons-material/";

// Own base components
import NavBar from "../../components/NavBar";
import PageHeader from "../../components/PageHeader";
import PageScreen from "../../components/PageScreen";
import Form from "../../components/Form";
import FormField from "../../components/FormField";
import Field from '../../components/RightModal/components/Field';

// Own scene components

// Navigation
import { useNavigate } from "react-router-dom";

// State
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appsActionCreators from "../../data/apps/actions";

// Dates and locales

// Other
import FeathersHelper from '../../helpers/feathers';
import defaults from '../../defaults.json';
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  formBox: {
    backgroundColor: '#FFFFFF',
    border: "1px solid #EEEEEF",
    borderRadius: 10,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    textTransform: "uppercase",
    color: "#686E71",
    fontSize: "0.916rem",
  },
  helperText: {
    color: "#B4B4B7",
    fontSize: "0.6875rem",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const termsForApp = (locales) => {
  const terms = {};
  locales.map(l => {
    terms[l] = defaults.terms[l] || defaults.terms.default;
  })
  return terms;
}

const titleForApp = (name, locales) => {
  const title = {};
  locales.map(l => {
    title[l] = name;
  })
  return title;
}

const NewApp = props => {

  const classes = useStyles();

  let navigate = useNavigate();

  const [myApps, setMyApps] = useState([]);
  const [appId, setAppId] = useState(null);

  const top100Films = [
    { label: 'The Godfather', id: 1 },
    { label: 'Pulp Fiction', id: 2 },
  ];

  useEffect(() => {
    FeathersHelper.find("apps", { query: { $sort: { updatedAt: -1 }, $limit: -1 } }).then(r => {
      setMyApps(r.map(a => ({ key: a.slug, label: `${a.name} (${a.slug})` })));
    });
  }, []);

  const renderFields = (
    newObject,
    valueForPath,
    setValueForPath,
    erroredFields,
    handleSave
  ) => (
    <Grid container spacing={2} justifyContent="center" >
      <Grid item lg={6} md={8}>
        <Box m={2}>
          <Paper>
            <Box p={3}>
              <Typography component="h3" variant="h3">
                Información de la app
              </Typography>
              <Box mt={3}>
                {myApps ?
                  <Box
                    px={2}
                    py={1}
                    id={props.id}
                    className={`${classes.formBox}`}
                  >
                    <Autocomplete
                      disablePortal
                      id="free-solo-demo"
                      options={myApps}
                      getOptionLabel={o => {
                        console.log(o);
                        return o.label || myApps.find(a => a.key == o).label;
                      }}
                      onChange={(e, v) => {
                        if (v) setAppId(v.key)
                        else setAppId(null)
                      }}
                      value={appId}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          fullWidth
                          label="Plantilla"
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                          placeholder="Sin plantilla (crear app nueva)"
                          helperText="Selecciona una app existente para duplicar su contenido o crea una nueva."
                          FormHelperTextProps={{
                            className: `${classes.helperText}`,
                          }}
                        />
                      )}
                    />
                  </Box>
                  : null}
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_slug`}
                  error={erroredFields("slug")}
                  definition={{
                    key: "slug",
                    type: "string",
                    label: "Slug de la app",
                    help: "Parte de la URL que designará la aplicación. Atención: una vez elegido, no se podrá cambiar.",
                  }}
                  size={12}
                  value={valueForPath("slug")}
                  onChange={(e) => {
                    setValueForPath("slug", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_name`}
                  error={erroredFields("name")}
                  definition={{
                    key: "name",
                    type: "string",
                    label: "Nombre de la app",
                    help: "Nombre interno para identificar la app",
                  }}
                  size={12}
                  value={valueForPath("name")}
                  onChange={(e) => {
                    setValueForPath("name", e.target.value);
                  }}
                />
              </Box>
              <Box mt={3}>
                <FormField
                  id={`field_language`}
                  error={erroredFields("langPattern.default")}
                  definition={{
                    key: "name",
                    type: "select",
                    options: defaults.availableLanguages.map(al => ({ key: al.code, label: al.name })),
                    label: "Idioma principal",
                    help:
                      "Podrás elegir otros idiomas una vez publiques tu app",
                  }}
                  size={12}
                  value={valueForPath("default")}
                  onChange={(e) => {
                    setValueForPath("langPattern.available", [e.target.value]);
                    setValueForPath("langPattern.default", e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        {/*<Box mx={2} my={8}>
          <Typography component="h3" variant="h3">
            Duplicando app "Seat"...
          </Typography>
          <LinearProgressWithLabel
            value={30}
          />
          <Typography component="small" variant="small">
            Copiando ajustes de la app ...
          </Typography>
                </Box>*/}
        <Box m={2} style={{ textAlign: 'right' }}>
          <Button color="primary" size='large' onClick={handleSave} variant="contained">
            Guardar
          </Button>
        </Box>

      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <NavBar
        crumbs={[
          { title: "Apps", path: "/" },
          { title: "Crear una nueva app" },
        ]}
      />
      <PageScreen className="NewApp">
        <PageHeader title="Crear una nueva app" renderActions={() => null} />
        <Form
          renderFields={renderFields}
          createAction={(payload) => {
            if (appId) {
              return props.actions.apps.create({ ...payload, terms: termsForApp(payload.langPattern?.available || []), title: titleForApp(payload.name, payload.langPattern?.available || []), appId })
            } else {
              return props.actions.apps.create({ ...payload, terms: termsForApp(payload.langPattern?.available || []), title: titleForApp(payload.name, payload.langPattern?.available || []) })
            }
          }}
          patchAction={(payload) =>
            props.actions.apps.patch(payload)
          }
          handleSuccess={(action, payload) => {
            window.location.href = `/${payload.slug}/settings`
          }}
        />
      </PageScreen>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    apps: state.data.apps,
  }),
  (dispatch) => ({
    actions: {
      apps: bindActionCreators(appsActionCreators, dispatch),
    },
  })
)(NewApp);
