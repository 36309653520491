import React, { Fragment, useCallback, useEffect, useState } from 'react';

import DataView from '../../components/DataView';

import FeathersHelper from '../../helpers/feathers';

import {
  Chip,
  Select,
  IconButton,
  Button
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  QrCode as QRIcon
} from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";



function getFullName(params) {
  return params.getValue ? `${params.getValue(params.id, 'firstName') || ''} ${
    params.getValue(params.id, 'lastName') || ''
  }` : 'caca';
}

export default props => {

  const [audiences, setAudiences] = useState([]);

  useEffect(() => {
    FeathersHelper.find('audiences', { query: { $limit: -1 } }).then( a => setAudiences(a));
  }, []);

  const AudienceValue = useCallback((props) => {
    const { item, applyValue } = props;

    const handleFilterChange = (event) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <Select
        native
        value={item.value}
        onChange={handleFilterChange}
      >
        <option key={`audience_filter_all`} value='all'>Cualquiera</option>
        <option key={`audience_filter_none`} value='none'>Ninguna</option>
        {audiences.map(a => (
          <option key={`audience_filter_${a._id}`} value={a._id}>{a.name}</option>
        ))}
      </Select>
    );

  }, [audiences]);

  const confirm = useConfirm();

  return (
    <DataView
      useGrid
      langs={props.langs}
      items={props.items}
      total={props.total}
      create={props.handleCreate}
      patch={props.handlePatch}
      removeMany={(ids) => {
        confirm({
          title: "¿Eliminar usuarios?",
          description: `Esta acción eliminará por completo los
          usuarios, así como todo su contenido asociado.
          Esta acción es permenente y no tiene marcha atrás.`,
          confirmationText: "Eliminar",
          confirmationButtonProps: { variant: "contained" },
          cancellationText: "Cancelar",
          cancellationButtonProps: { variant: "contained" },
        })
          .then(() => {
            props.handleRemoveMany(ids)
          })
          .catch(() => {});
      }}
      // IMPORT EXPORT
      excelColumns={[
        {
          key: '_id',
          header: "Id"
        },
        {
          key: 'email',
          header: 'Email',
        },
        {
          key: 'firstName',
          header: 'Nombre'
        },
        {
          key: 'lastName',
          header: 'Apellidos'
        },
        {
          key: 'subtitle',
          header: 'Subtítulo',
        },
        {
          key: 'password',
          header: 'Contraseña',
          value: (item) => '',
          importValue: (item) => {
            return item.password ? item.password.toString() : item.password;
          }
        },
        {
          key: 'audiences',
          header: 'Audiencias',
          value: (item) => item.audiences.join(','),
          importValue: (item) => item.audiences ? item.audiences.split(',') : []
        },
        {
          key: 'invisible',
          header: 'Invisible',
          value: (item) => item.invisible ? 'Si' : 'No',
          importValue: (item) => item.invisible == 'Si'
        },
        {
          key: 'fastpassEnabled',
          header: 'Habilitar Fastpass',
          value: (item) => item.fastpassEnabled ? 'Si' : 'No',
          importValue: (item) => item.fastpassEnabled == 'Si'
        },
        {
          key: 'hideFromRanking',
          header: 'Esconder en rankings',
          value: (item) => item.hideFromRanking ? 'Si' : 'No',
          importValue: (item) => item.hideFromRanking == 'Si'
        }
      ]}
      importFormatter={(items) => {
        return items.map( (i) => {
          const { password, ...rest } = i;
          if (password == '') return rest;
          else return i;
        });
      }}
      humanize={(item) => `${item.email}`}
      importHandler={true}
      // IMPORT EXPORT END
      extraActions={(selectedRows) => {
        return (
          <Button size="small" variant="contained" color="secondary" onClick={() => props.downloadTickets(selectedRows)} startIcon={<QRIcon />} style={{ marginRight: 5 }}>
            Descargar QRs
          </Button>
        )
      }}
      columnDefs={[
        {
          field: "import",
          headerName: "Operación",
          valueGetter: (params) => params.row._id ? 'Modificación' : 'Inserción',
          flex: 1.1
        },
        {
          field: "_id",
          headerName: "Id",
          hide: true,
          flex: 1.1
        },
        {
          field: "firstName",
          headerName: "Nombre",
          flex: 2
        },
        {
          field: "lastName",
          headerName: "Apellidos",
          flex: 2
        },
        { field: "email", headerName: "E-mail", flex: 1 },
        { field: "subtitle", headerName: "Subtítulo", flex: 1 },
        {
          field: "audiences",
          headerName: "Audiencias",
          //valueGetter: (params) => (params.value||[]).map(a => audienceLabel(a)),
          renderCell: (params) => (
            <div className='Audiences' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'scroll' }}>
              {(params.getValue(params.id, 'audiences')||[]).map(a => {
                if (typeof a == 'string') return audiences.find(aa => aa._id == a);
                else return a;
              }).map((a,k) => (
                <Chip
                  key={a?._id}
                  color="primary"
                  size="small"
                  label={a?.name}
                  className="Audience"
                />
              ))}
            </div>
          ),
          // sortComparator: (v1, v2, cellParams1, cellParams2) =>
          //   (cellParams1.value||[]).map(a => audienceLabel(a)).join(' ').localeCompare((cellParams2.value||[]).map(a => audienceLabel(a)).join(' ')),
          filterOperators: [
            {
              label: 'Seleccionar audiencia',
              value: 'from',
              getApplyFilterFn: (filterItem) => {
                if (
                  !filterItem.columnField ||
                  !filterItem.value ||
                  !filterItem.operatorValue
                ) {
                  return null;
                }

                return (params) => {
                  console.log("Params value is", params.value);
                  console.log("filterItem is", filterItem.value);
                  if (filterItem.value == 'all') return true;
                  else if (filterItem.value == 'none' && params.value.length == 0) return true;
                  else if (filterItem.value && params.value.length > 0) {
                    return params.value.map(a => (a._id||a||'').toString()).includes(filterItem.value.toString());
                  }
                };
              },
              InputComponent: AudienceValue,
              InputComponentProps: { type: 'string' },
            },
          ],
          flex: 1
        },
        {
          field: "invisible",
          headerName: "Invisible",
          valueGetter: (params) => params.value ? 'Si' : 'No',
          flex: 1
        },
        {
          field: "hideFromRanking",
          headerName: "Esconder en rankings",
          valueGetter: (params) => params.value ? 'Si' : 'No',
          flex: 1
        },
        {
          field: "fastpassEnabled",
          headerName: "Fastpass",
          hide: false,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            const fastpassEnabled = params.value;
            if (fastpassEnabled) {
              return (
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    props.downloadTicket(id);
                  }}
                >
                  <QRIcon fontSize="small" />
                </IconButton>
              );
            } else {
              return 'No';
            }
          },
          flex: 1
        },
        {
          field: "actions",
          skipExcel: true,
          headerName: "Acciones",
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const id = params.getValue(params.id, "_id");
            return (
              <Fragment>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="EditButton"
                  onClick={() => {
                    const item = props.items.filter(i => i._id == id)[0];
                    props.handleEdit(item);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="delete"
                  className="DeleteButton"
                  onClick={() => {
                    confirm({
                      title: "¿Eliminar usuario?",
                      description: `Esta acción eliminará por completo el
                      usuario, así como todo su contenido asociado.
                      Esta acción es permenente y no tiene marcha atrás.`,
                      confirmationText: "Eliminar",
                      confirmationButtonProps: { variant: "contained" },
                      cancellationText: "Cancelar",
                      cancellationButtonProps: { variant: "contained" },
                    })
                      .then(() => {
                        const item = props.items.filter(i => i._id == id)[0];
                        props.handleRemove(item)
                      })
                      .catch(() => {});
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Fragment>
            );
          }
        },
      ]}
      empty={{
        title: "Añade tu primer usuario",
        body:
          "Añade usuarios a tu app y permíteles así acceder con sus credenciales.",
        cta: "+ Crear nuevo usuario",
        action: props.handleNew,
      }}
    />
  );
}