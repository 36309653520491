import React, { useState, useCallback, useEffect } from "react";

import {
  Typography,
  Modal,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Fade,
  Backdrop,
  Switch
} from "@mui/material";

import { scaledImageSource } from "../../components/ScaledImage";
import ChipCheckboxGroup from "./components/ChipCheckboxGroup";
import IconField from "./components/IconField";
import TransferList from "./components/TransferList";

import { makeStyles } from "@mui/styles";
import VisibilityBlock from "./components/VisibilityBlock";
import ColorPicker from "./components/ColorPicker";

import { useDropzone } from "react-dropzone";

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import nativeMoment from "moment-timezone";

import DateTimePicker from 'react-datetime-picker';

import FeathersHelper from '../../helpers/feathers'

import axios from "axios";

registerLocale('es', es)
setDefaultLocale(es);

const useStyles = makeStyles((theme) => ({
  formBox: {
    border: "1px solid #EEEEEF",
    borderRadius: 10,
    flexGrow: 1,
  },
  formBoxHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  imageDrop: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#FAFAFA",
    border: "1px solid #EEEEEF",
    overflow: "hidden",
  },
  fileDropInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      maxWidth: '90%'
    },
  },
  imageDropInner: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      width: 90,
      maxWidth: '90%'
    },
  },
  uploadButton: {
    paddingHorizontal: 0,
    minWidth: 0,
  },
  label: {
    textTransform: "uppercase",
    color: "#686E71",
    fontSize: "0.916rem",
  },
  helperText: {
    color: "#B4B4B7",
    fontSize: "0.6875rem",
  },
  error: {
    borderColor: "#FF6666",
    color: "#FF6666",
  },
}));

export default props => {

  const classes = useStyles();

  const [customState, setCustomState] = useState(false);

  const [imageData, setImageData] = useState(null);

  const [uploading, setUploading] = useState(false);

  const [uploadPercent, setUploadPercent] = useState(0);

  const onDrop = (acceptedFiles) => {

    setUploading(true);

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => {
        setUploading(true);
        //console.log("file reading was aborted")
      };
      reader.onerror = () => {
        setUploading(false);
        //console.log("file reading has failed")
      };
      reader.onload = () => {
        // Do whatever you want with the file contents
        //const binaryStr = reader.result;
        //console.log(binaryStr);
        //setImageData(reader.result.toString());

        // DIRECT HTTP METHOD
        // axios.post(`${process.env.REACT_APP_API_URL}/uploads/`, { uri: reader.result })
        //   .then(function (response) {
        //     // success
        //     setUploading(false);
        //     console.log("Server responded with: ", response);
        //     props.onChange( { target: { value: response.id } } );
        //   })
        //   .catch(function (e) {
        //     alert(JSON.stringify(e));
        //     setUploading(false);
        //   });

        // FEATHERS STUFF
        // const uploadService = FeathersHelper.app.service("uploads");
        // var upload = uploadService
        //   .create({ uri: reader.result })
        //   .then(function (response) {
        //     // success
        //     setUploading(false);
        //     console.log("Server responded with: ", response);
        //     props.onChange( { target: { value: response.id } } );
        //   })
        //   .catch( e => {
        //     alert(JSON.stringify(e));
        //     setUploading(false);
        //   });

        // FEATHERS ONLY GET SIGNED AND THEN DIRECT
        var upload = FeathersHelper
          .create("storage", { filename: file.name, filetype: file.type })
          .then(function (response) {
            const { signedURL, finalKey } = response;
            const config = {
              onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setUploadPercent(percentCompleted);
                //console.log(percentCompleted)
              }
            }
            axios.put( signedURL, file, config)
              .then(res => {
                //console.log(res);
                setUploading(false);
                setUploadPercent(0);
                props.onChange( { target: { value: finalKey } } );
              })
              .catch(err => {
                //console.log(err.response);
              })

            // success
            // setUploading(false);
            // console.log("Server responded with: ", response);
            // props.onChange( { target: { value: response.id } } );
          })
          .catch( e => {
            alert(JSON.stringify(e));
            setUploading(false);
          });
      };
      //console.log("THE RECEIVED FILE", file);
      reader.readAsDataURL(file);

    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  switch (props.definition.type) {
    case "file":
      return (
        <Grid
          item
          md={props.size}
          style={{ display: "flex", flexDirection: "row" }}
          id={props.definition.id}
        >
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
              {props.definition.label}
            </InputLabel>
            <div
              className={classes.imageDrop}
              style={{
                borderRadius: props.definition.borderRadius,
              }}
              {...getRootProps()}
            >
              <div
                className={classes.fileDropInner}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : uploading ? <p>Subiendo... ({uploadPercent}%)</p> : props.value ? (
                  <p>{props.value}</p>
                ) : (
                  <p>Drag 'n' drop or select files</p>
                )}
              </div>
            </div>
          </Box>
        </Grid>
      );
    case "image":
      return (
        <Grid
          item
          md={props.size}
          style={{ display: "flex", flexDirection: "row" }}
          id={props.definition.id}
        >
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
              {props.definition.label}
            </InputLabel>
            {/*<div style={{ width: '80%', paddingTop: '-50%', position: 'relative', borderRadius: 50, backgroundColor: 'green'}}></div>*/}
            <div
              className={classes.imageDrop}
              style={{
                paddingTop: `${(1 / props.definition.aspectRatio) * 100}%`,
                position: "relative",
                borderRadius: props.definition.borderRadius,
              }}
              {...getRootProps()}
            >
              <div
                className={classes.imageDropInner}
                style={
                  props.value && !uploading ? {
                    backgroundImage: `url(${scaledImageSource(
                      props.value,
                      props.definition.width,
                      props.definition.height,
                      true
                    )})`,
                  } : {}
                }
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : uploading ? <p>Subiendo... ({uploadPercent}%)</p> : props.value ? (
                  null
                ) : (
                  <p>Drag 'n' drop or select files</p>
                )}
              </div>
            </div>
          </Box>
        </Grid>
      );
    case "string":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <TextField
              fullWidth
              id="standard-helperText"
              label={props.definition.label}
              InputLabelProps={{
                shrink: true,
                className: classes.label,
              }}
              placeholder={props.definition.label}
              helperText={props.error || props.definition.help}
              FormHelperTextProps={{
                className: `${classes.helperText} ${
                  props.error ? classes.error : null
                }`,
              }}
              value={props.value}
              onChange={props.onChange}
            />
          </Box>
        </Grid>
      );
    case "timezone":
      return (
        <Grid item md={props.size}  id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
              {props.definition.label}
            </InputLabel>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <Select
                native
                displayEmpty
                value={props.value}
                onChange={props.onChange}
              >
                <option value='Africa/Abidjan'>Africa/Abidjan</option>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      );
    case "datetime":
      return (
        <Grid item md={props.size}  id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            {/*
            <DatePicker
              selected={props.value}
              onChange={date => props.onChange( { target: { value: date } } )}
              locale="es"
              minDate={new Date()}
              showTimeInput
              timeInputLabel="Hora:"
              dateFormat="dd/MM/yyy HH:mm"
              placeholderText="Elige una fecha y hora"
            />
            */}
            <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
              {props.definition.label}
            </InputLabel>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              {/*}
              <DateTimePicker
                format="dd/MM/yyyy HH:mm"
                onChange={v => {
                  var coeff = 1000 * 60 * 5;
                  var rounded = new Date(Math.round(v.getTime() / coeff) * coeff)
                  props.onChange({ target: { value: rounded} });
                }}
                value={props.value}
              />
              */}
              <DatePicker
                selected={props.value ? new Date(props.value) : null}
                locale="es"
                onChange={ v => {
                  props.onChange({ target: { value: nativeMoment(v).format("YYYY-MM-DD HH:mm:00")} })
                }}
                showTimeSelect
                timeIntervals={5}
                dateFormat="dd/MM/yyyy HH:mm"
              />
            </FormControl>
            {/*
            <TextField
              fullWidth
              id="standard-helperText"
              type="datetime-local"
              step={300}
              defaultValue={props.definition.defaultValue}
              label={props.definition.label}
              InputLabelProps={{
                shrink: true,
                className: classes.label,
              }}
              placeholder={props.definition.label}
              helperText={props.error || props.definition.help}
              FormHelperTextProps={{
                className: `${classes.helperText} ${
                  props.error ? classes.error : null
                }`,
              }}
              value={props.value}
              onChange={props.onChange}
            />
            */}

          </Box>
        </Grid>
      );
    case "switch":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${classes.formBoxHorizontal} ${
              props.error ? classes.error : null
            }`}
          >
            <div>
              <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
                {props.definition.label}
              </InputLabel>
              <FormHelperText
                className={`${classes.helperText} ${
                  props.error ? classes.error : null
                }`}
              >
                {props.error || props.definition.help}
              </FormHelperText>
            </div>
            <Switch
              checked={props.value}
              onChange={(e) => {
                props.onChange({ target: { value: e.target.checked }});
                if (props.definition.onChange) {
                  props.definition.onChange(e.target.checked);
                }
              }}
              color="primary"
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Box>
        </Grid>
      );
    case "icon":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <IconField
              fullWidth
              id="standard-helperText"
              label={props.definition.label}
              InputLabelProps={{
                shrink: true,
                className: classes.label,
              }}
              placeholder={props.definition.label}
              helperText={props.error || props.definition.help}
              FormHelperTextProps={{
                className: `${classes.helperText} ${
                  props.error ? classes.error : null
                }`,
              }}
              value={(props.value && props.value.indexOf('icon-sm') == -1 && props.value.indexOf('icon-md') == -1) ? `icon-md-${props.value}` : props.value}
              onChange={props.onChange}
            />
          </Box>
        </Grid>
      );
    case "select":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
              {props.definition.label}
            </InputLabel>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              {props.definition.options && props.definition.options.length > 0 ?
                <Select
                  displayEmpty
                  value={props.value}
                  onChange={props.onChange}
                >
                  {props.definition.options.map((o) => (
                    <MenuItem value={o.key}>{o.label}</MenuItem>
                  ))}
                </Select>
              : null}
            </FormControl>
          </Box>
        </Grid>
      );
    case "wrapper":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${props.error ? classes.error : null
              }`}
          >
            <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
              {props.definition.label}
            </InputLabel>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              {props.children}
            </FormControl>
          </Box>
        </Grid>
      );
    case "transferList":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <InputLabel className={["MuiInputLabel-shrink", classes.label]}>
              {props.definition.label}
            </InputLabel>
            <TransferList
              options={props.definition.options}
              value={props.value}
              onChange={props.onChange}
            />
          </Box>
        </Grid>
      );
    case "text":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <TextField
              fullWidth
              multiline
              rows={4}
              id="standard-helperText"
              label={props.definition.label}
              InputLabelProps={{
                shrink: true,
                className: classes.label,
              }}
              placeholder={props.definition.label}
              helperText={props.error || props.definition.help}
              FormHelperTextProps={{
                className: `${classes.helperText} ${
                  props.error ? classes.error : null
                }`,
              }}
              value={props.value}
              onChange={props.onChange}
            />
          </Box>
        </Grid>
      );
    case "audienceVisibility":
      //console.log("Value", props.value);
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <VisibilityBlock
              definition={props.definition}
              value={props.value || {}}
              onChange={props.onChange}
              error={props.error}
            />
          </Box>
        </Grid>
      );
    case "color":
      return (
        <Grid item md={props.size} id={props.definition.id}>
          <Box
            p={2}
            id={props.id}
            className={`${classes.formBox} ${
              props.error ? classes.error : null
            }`}
          >
            <ColorPicker
              definition={props.definition}
              value={props.value}
              onChange={props.onChange}
              error={props.error}
            />
          </Box>
        </Grid>
      );
    default:
      return (
        <Grid item md={12} id={props.definition.id}>
          Unknown field
        </Grid>
      );
  }
}