import React from 'react';
import { Navigate,Outlet } from 'react-router-dom';

import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import ReportLayout from './layouts/ReportLayout';

import Home from "./scenes/Home";
import Lite from "./scenes/Lite";
import Monitor from "./scenes/Monitor";
import Atlas from "./scenes/Atlas";
import Storage from "./scenes/Storage";
import Licenses from './scenes/Licenses';
import Wizard from "./scenes/Wizard";
import NewApp from "./scenes/NewApp";
import Settings from "./scenes/Settings";
import Modules from "./scenes/Modules";
import Appointments from "./scenes/Appointments";
import StaticContents from "./scenes/StaticContents";
import Questions from "./scenes/Questions";
import QuestionReport from "./scenes/QuestionReports/QuestionReport";
import QuestionModeration from "./scenes/QuestionReports/QuestionReport";
import Games from "./scenes/Games";
import VotingBlocks from './scenes/VotingBlocks';
import Posts from "./scenes/Posts";
import Users from "./scenes/Users";
import InviteCodes from "./scenes/InviteCodes";
import NewTables from "./scenes/NewTables";
import UsersImport from "./scenes/UsersImport";
import Audiences from "./scenes/Audiences";
import VirtualRooms from "./scenes/VirtualRooms";
import Notifications from "./scenes/Notifications";
import Announcements from "./scenes/Announcements";
import Alerts from "./scenes/Alerts";
import Reports from "./scenes/Reports";
import Analytics from "./scenes/Analytics";
import Gaming from "./scenes/Gaming";
import Networking from "./scenes/Networking";
import Fastpass from './scenes/Fastpass';
import QR from './scenes/QR';
import Login from "./scenes/Login";
import SignUp from "./scenes/SignUp";

import Projection from "./scenes/Projection";
import ProjectionStatic from './scenes/ProjectionStatic';
import ProjectionData from './scenes/ProjectionData';
import Admins from './scenes/Admins';




const routes = (isLoggedIn, selectedApp) => [
  {
    path: '/',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/new', element: <NewApp /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '/lite', element: <Lite /> },
      { path: '/monitor', element: <Monitor /> },
      { path: '/atlas', element: <Atlas /> },
      { path: '/storage', element: <Storage /> },
      { path: '/licenses', element: <Licenses /> },
      { path: '/admins', element: <Admins /> },
    ],
  },
  {
    path: '/',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: '/:slug', element: <Modules /> },
      { path: '/:slug/settings', element: <Settings /> },
      { path: '/:slug/module/:moduleId/appointments', element: <Appointments /> },
      { path: '/:slug/module/:moduleId/static-contents', element: <StaticContents /> },
      { path: '/:slug/module/:moduleId/blocks', element: <Modules /> },
      { path: '/:slug/module/:moduleId/questions', element: <Questions /> },
      { path: '/:slug/module/:moduleId/games', element: <Games /> },
      { path: '/:slug/module/:moduleId/voting-blocks', element: <VotingBlocks /> },
      { path: '/:slug/module/:moduleId/posts', element: <Posts /> },
      { path: '/:slug/virtual-rooms', element: <VirtualRooms /> },
      { path: '/:slug/audiences', element: <Audiences /> },
      { path: '/:slug/invitecodes', element: <InviteCodes /> },
      { path: '/:slug/users', element: <Users /> },
      { path: '/:slug/users/import', element: <UsersImport /> },
      { path: '/:slug/newtables', element: <NewTables /> },
      { path: '/:slug/notifications', element: <Notifications /> },
      { path: '/:slug/announcements', element: <Announcements /> },
      { path: '/:slug/alerts', element: <Alerts /> },
      { path: '/:slug/reports', element: <Reports /> },
      { path: '/:slug/analytics', element: <Analytics /> },
      { path: '/:slug/gaming', element: <Gaming /> },
      { path: '/:slug/networking', element: <Networking /> },
      { path: '/:slug/fastpass', element: <Fastpass /> },
      { path: '/:slug/qr', element: <QR /> },
    ],
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'signup', element: <SignUp /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
  {
    path: '/',
    element: <ReportLayout />,
    children: [
      { path: '/:slug/module/:moduleId/questions/moderation', element: <QuestionReport master={true} /> },
      { path: '/:slug/module/:moduleId/questions/report', element: <QuestionReport master={false} /> },
      { path: '/:slug/projections/:moduleId', element: <Projection /> },
      { path: '/:slug/projections-static/:moduleId', element: <ProjectionStatic /> },
      { path: '/:slug/projections-data/:moduleId', element: <ProjectionData /> },
    ],
  },
];

export default routes;