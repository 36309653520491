import React, { useState } from 'react';

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";

import { connect } from "react-redux";

import { scaledImageSource } from "../../../../../../components/ScaledImage";

import FeathersHelper from '../../../../../../helpers/feathers';

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  imageDrop: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#FAFAFA",
    border: "1px solid #EEEEEF",
    overflow: "hidden",
    position: 'relative'
  },
  fileDropInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      maxWidth: '90%'
    },
  },
  imageDropInner: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      width: 90,
      maxWidth: '90%'
    },
  },
}));

const ImageField = props => {

  const [uploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const classes = useStyles();

  const onDrop = (acceptedFiles) => {

    setUploading(true);

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => {
        setUploading(true);
        console.log("file reading was aborted")
      };
      reader.onerror = () => {
        setUploading(false);
        console.log("file reading has failed")
      };
      reader.onload = () => {
        // FEATHERS STUFF
        const uploadService = FeathersHelper.app.service("uploads");
        var upload = uploadService
          .create({ uri: reader.result })
          .then(function (response) {
            // success
            setUploading(false);
            console.log("Server responded with: ", response);
            props.onChange( { target: { value: response.id } } );
          })
          .catch( e => {
            //alert(JSON.stringify(e));
            console.log("Image upload error", e)
            setUploading(false);
          });

        // FEATHERS ONLY GET SIGNED AND THEN DIRECT
        // var upload = FeathersHelper
        //   .create("storage", { filename: file.name, filetype: file.type })
        //   .then(function (response) {
        //     const { signedURL, finalKey } = response;
        //     const config = {
        //       onUploadProgress: function (progressEvent) {
        //         var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         setUploadPercent(percentCompleted);
        //         //console.log(percentCompleted)
        //       }
        //     }
        //     axios.put(signedURL, file, config)
        //       .then(res => {
        //         //console.log(res);
        //         setUploading(false);
        //         setUploadPercent(0);
        //         props.onChange({ target: { value: finalKey } });
        //       })
        //       .catch(err => {
        //         //console.log(err.response);
        //       })

        //     // success
        //     // setUploading(false);
        //     // console.log("Server responded with: ", response);
        //     // props.onChange( { target: { value: response.id } } );
        //   })
        //   .catch(e => {
        //     alert(JSON.stringify(e));
        //     setUploading(false);
        //   });

        // HYBRID HTTP WITH TOKEN WAY
        // const config = {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        //   onUploadProgress: function (progressEvent) {
        //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //     setUploadPercent(percentCompleted);
        //     //console.log(percentCompleted)
        //   }
        // };
        // const formData = new FormData();
        // formData.append("uri", reader.result);
        // formData.append("appId", props.selectedApp.slug);
        // axios.post(`${process.env.REACT_APP_API_URL}/uploads`, formData, config).then(res => {
        //   //console.log(res);
        //   console.log(res);
        //   setUploading(false);
        //   setUploadPercent(0);
        //   props.onChange({ target: { value: res.data.id } });
        // })
        //   .catch(err => {
        //     console.log(err.response);
        //   })

      };
      console.log("THE RECEIVED FILE", file);
      reader.readAsDataURL(file);

    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const value = props.value();

  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        __{props.definition.label}
      </InputLabel>
      <div
        className={classes.imageDrop}
        style={{
          paddingTop: `${(1 / props.definition.aspectRatio) * 100}%`,
          position: "relative",
          borderRadius: props.definition.borderRadius,
        }}
        {...getRootProps()}
      >
        <div
          className={classes.imageDropInner}
          style={
            value && {
              backgroundImage: `url(${scaledImageSource(
                value,
                props.definition.width,
                props.definition.height,
                true
              )})`,
            }
          }
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : value ? null : uploading ? (
            <p>Subiendo... ({uploadPercent}%)</p>
          ) : (
            <p>Drag 'n' drop or select files</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
  }),
  null
)(ImageField);