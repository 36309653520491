import store from '../store';
import { update as updateApp } from '../services/app/actions';

import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "socket.io-client";
import FirebaseHelper from './firebase';

const { createHash } = require("crypto");



const processParams = params => {
  // const state = store.getState();
  // const slug = state.data.apps.selected.slug;
  const slug = window.location.pathname.split("/").filter((p) => p !== "")[0];
  if (slug && !["new", "licenses", "admins"].includes(slug)) {
    return { ...params, query: { ...(params.query ? params.query : {}), appId: slug } }
  } else {
    return params;
  }
}

const processData = data => {
  // const state = store.getState();
  // const slug = state.data.apps.selected.slug;
  const slug = window.location.pathname.split("/").filter((p) => p !== "")[0];
  if (slug && !["new", "licenses", "admins"].includes(slug)) {
    if (Array.isArray(data)) {
      return data.map(i => ({ ...i, appId: slug }));
    } else {
      return { ...data, appId: slug };
    }

  } else {
    return data;
  }
}

class FeathersHelper {
  static instance = null;

  constructor() {
    if (FeathersHelper.instance) {
      return FeathersHelper.instance;
    }

    this._type = "FeathersHelper";
    FeathersHelper.instance = this;

    const timestamp = Date.now();
    const digest = createHash("sha256")
      .update(`${JSON.stringify(timestamp)}_kCIAcdkGU`)
      .digest("hex");

    this.socket = io(process.env.REACT_APP_API_URL, {
      transports: ["websocket"],
      forceNew: true,
      query: {
        platform: 'backoffice'
      //   appslug: "5e9b1e1ef159c22cecd52ea4",
      //   timestamp: timestamp,
      //   hash: digest,
      }
    });
    this.app = feathers();

    // Set up Socket.io client with the socket
    this.app.configure(socketio(this.socket), {
      timeout: 20000
    });

    //this.app.service('projections').timeout = 30000;
    this.app.service('jobs').timeout = 30000;
    this.app.service('reports').timeout = 30000;
    this.app.service('notifications').timeout = 30000;

    this.app.configure(
      auth({
        storage: window.localStorage,
        path: "/authentication/admins",
        storageKey: `ubelong_admin_jwt`,
      })
    );

    // this.app.hooks({
    //   before: {
    //     all: (context) => {
    //       const slug = window.location.pathname.split("/").filter((p) => p !== "")[0];
    //       context.t = FirebaseHelper.trace('API_REQUEST');
    //       context.t.start();
    //       if (slug) context.t.putAttribute("slug", slug);
    //       else context.t.putAttribute("slug", "NONE");
    //       context.t.putAttribute("path", context.path);
    //       context.t.putAttribute("method", context.method);
    //     }
    //   },
    //   after: {
    //     all: (context) => {
    //       context.t.putAttribute("response", "200");
    //       context.t.stop();
    //     }
    //   },
    //   error: {
    //     all: (context) => {
    //       context.t.putAttribute("response", `${context.error.code}`);
    //       context.t.stop();
    //     }
    //   },
    // });



    // Bindings
    this.socket.on("connect", (connection) => {

      this.app.reAuthenticate()
        .then(() => {
          store.dispatch(updateApp({ feathersConnected: true, feathersAuthenticated: true }));
          // Retrieve user
          this.app.get("authentication").then((r) => {
            console.log("R",r)
            if (r) {
              store.dispatch(updateApp({ feathersUser: r.admin }));
            } else {
              store.dispatch(updateApp({ feathersConnected: true, feathersAuthenticated: false }));
            }

          });
        })
        .catch(() => {
          store.dispatch(updateApp({ feathersConnected: true, feathersAuthenticated: false }));
        }).finally( () => {
          store.dispatch(updateApp({ feathersReady: true }));

        });
    });

    this.socket.on("disconnect", (connection, ext) => {
      console.log("Disc", connection)
      console.log("ext")
      store.dispatch(updateApp({ feathersConnected: false }));
    });

  }

  find = (serviceName, params = {}) => this.app.service(serviceName).find(processParams(params))
  get = (serviceName, id, params = {}) => this.app.service(serviceName).get(id, params)
  create = (serviceName, data, params = {}) => this.app.service(serviceName).create(processData(data), params)
  update = (serviceName, id, data, params = {}) => this.app.service(serviceName).update(id, data, params)
  patch = (serviceName, id, data, params = {}) => this.app.service(serviceName).patch(id, data, params)
  remove = (serviceName, id, params = {}) => this.app.service(serviceName).remove(id, params)

  authenticate = (email, password) => this.app.authenticate({
    strategy: "local",
    email: email,
    password: password,
  })
    .then((r) => {
      store.dispatch(updateApp({ feathersAuthenticated: true, feathersUser: r.user }));
    })
    .catch((e) => {
      store.dispatch(updateApp({ feathersAuthenticated: false, feathersUser: {} }));
      throw e;
    });

  logOut = () => this.app.logout().then(() => {
    store.dispatch(updateApp({ feathersAuthenticated: false, feathersUser: {} }));
  });

}

export default new FeathersHelper();